<template>
  <div>
    <b-alert
      show
      variant="light"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b"
    >
      <div class="alert-icon">
        <span class="svg-icon svg-icon-lg">
          <inline-svg src="media/svg/icons/Tools/Tools.svg" />
        </span>
      </div>
      <div class="alert-text">
        Mapping PapiKostick
      </div>
    </b-alert>

    <div  class="card shadow-xs cardHover">
      <!-- Tabel -->
      <label ></label>
      <label ></label>
      <div class="form-group">
        <label for="name"></label>
        <b-form-group id="input-group-2"  label-for="input-2" class="mb-2 mr-sm-2 mb-sm-0">
       <b-form-input
         id="input-2"
         v-model="form.name"
         required
         placeholder="Search"
         class="mb-2 mr-sm-2 mb-sm-0"
       ></b-form-input>
     </b-form-group>

      </div>
      <b-alert
        show
        variant="light"
        class="alert alert-custom alert-white alert-shadow fade show gutter-b"
      > 
        <b-table
          id="my-table"
          :filter="form.name"
          :fields="fields"
          striped 
          hover 
          :items="items"
          :per-page="perPage"
          :current-page="currentPage"
        >
        
        <template #cell(action)="row">
          <b-dropdown
                  size="sm"
                  variant="link"
                  toggle-class="custom-v-dropdown"
                  no-caret
                  dropup text="Drop-Up"
                  right
                  no-flip
                  v-b-tooltip.hover="'Update'"
                  ref="myDropdown"
                >
                  <template v-slot:button-content>
                    <a class="btn btn-icon" data-toggle="dropdown">
                      <i @click="updateItem(row.item)" class="card-title font-weight-bold text-muted text-hover-success flaticon2 flaticon2-pen">&nbsp; </i>

                    </a>
                  </template>
                  <!-- Navigation -->
                  <div class="navi navi-hover min-w-md-650px">
                    <b-dropdown-text tag="div" class="navi-header font-weight-bold">
                      Update Data:
                    </b-dropdown-text>
                    <b-dropdown-text tag="div" class="navi-separator mb-3"></b-dropdown-text>
                    <b-dropdown-text tag="div" class="navi-item">
                    <a  class="navi-link">
                      <span class="navi-icon">
                        <i class="flaticon2-expand"></i>
                      </span>
                      <b-form-input
                        size="sm"
                        placeholder="Title"
                        v-model="update_data.typetest_name"
                        disabled
                      />
                    </a>
                  </b-dropdown-text>
                    <b-dropdown-text tag="div" class="navi-item">
                      <a  class="navi-link">
                        <span class="navi-icon">
                          <i class="flaticon2-list"></i>
                        </span>
                        <b-form-select size="sm" v-model="update_data.rules_id" :options="options"></b-form-select>
                      </a>
                    </b-dropdown-text>

                      <b-dropdown-text tag="div" class="navi-footer">
                        <a
                        class="btn btn-light-primary font-weight-bolder btn-sm"  
                        @click="updateData"
                          >Update</a>
                      </b-dropdown-text>
                  </div>
                  <!-- End Navigation -->
          </b-dropdown>
        </template>
      </b-table>
      </b-alert>

      <!-- Pagination -->
      <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        aria-controls="my-table"
        size="lg"
        class="mt-3"
        align="center"
      ></b-pagination>
    </div>
  </div>
</template>

<script>
import KTCodePreview from "@/view/content/CodePreview.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Services from "@/core/services/psikologi-api/Services";
import ApiService from "@/core/services/api.service";
import Swal from 'sweetalert2'

export default {
  data() {
    return {
      selected: null,
      criteria: "", // Properti filter
      // fields: ['age_from', 'age_to', 'score', 'action'],
      fields: ['TypetestName', 'RulesName', 'action'],
      items: [],
      perPage: 10,
      currentPage: 1,
      form: {
        name: '',
      },
      add_data: {
        name: '',
      },
      update_data: {
        typetest_id: '',
        typetest_name: '',
        rules_id: '',
        rules_name: '',
      },
      // options: [
      //   { value: 'CFIT', text: 'CFIT' },
      //   { value: 'MODALITAS_BELAJAR', text: 'Modalitas Belajar' },
      //   { value: 'MINAT_BAKAT', text: 'Minat Bakat' },
      //   { value: 'PAPI_KOSTICK', text: 'Papi Kostick' },
      // ],
      options: [],
      show: true
    };
  },
  components: {
  },
  computed: {
     rows() {
       return this.items.length
     }
   },
  methods: {
    filterTable(row, filter) {
      // Ganti row.age_from sesuai dengan kolom yang ingin di-filter
      if (row.age_from.includes(filter)) {
        return true;
      } else {
        return false;
      }
    },

    onSubmit(evt) {
      evt.preventDefault()
      alert(JSON.stringify(this.form))
    },
    onReset(evt) {
      evt.preventDefault()
      this.form.name = ''
      this.show = false
      this.$nextTick(() => {
        this.show = true
      })
    },

    
    getMasterAgeScore1() {
      return new Promise((resolve, reject) => {
        let contentType = `application/form-data`;
        Services.GetData(
          ApiService,
          `master/rules-papi-kostick`,
          response => {
            // Mengganti items dengan data dari API
            resolve(response.data);
            // this.items = response.data
            this.options = response.data.map(item => ({
              value: item.Id,
              text: item.Name,
            }));

            // Resolve promise
          },
          err => {
            Swal.fire({
              title: "Data Not Found",
              icon: "error",
              confirmButtonClass: "btn btn-secondary",
              heightAuto: true,
              timer: 1500
            });
            reject(err);
          }
        );
      });
    },
  
    getMasterAgeScore() {
      return new Promise((resolve, reject) => {
        let contentType = `application/form-data`;
        Services.GetData(
          ApiService,
          `master/papi-kostick-map`,
          response => {
            // Mengganti items dengan data dari API
            resolve(response.data);
            this.items = response.data
            // this.items = response.data.map(item => ({
            //   score: item.Score,
            //   age_from: item.AgeRangeFrom,
            //   age_to: item.AgeRangeTo,
            //   action: item.AgeScoreID
            // }));

            // Resolve promise
          },
          err => {
            Swal.fire({
              title: "Data Not Found",
              icon: "error",
              confirmButtonClass: "btn btn-secondary",
              heightAuto: true,
              timer: 1500
            });
            reject(err);
          }
        );
      });
    },

    saveData() {
      const formData = new FormData();
      formData.append("Name", this.add_data.name);

      let contentType = `application/form-data`;

      Services.PostData(
        ApiService,
        `master/rules-papi-kostick/`,
        formData,
        contentType,
        // onSuccess function
        response => {
          if (response.status) {
            Swal.fire({
              title: "",
              text: "Data saved successfully.",
              icon: "success",
              heightAuto: true,
              timer: 1500
            });
            this.loadData();
          } else {
            Swal.fire({
              title: "",
              text: response.data.error,
              icon: "info",
              heightAuto: true,
              timer: 1500
            });
          }
        },
        // onError function
        error => {
          console.error('Error during API call:', error);
          // Handle unexpected errors
        }
      )
    },

    updateItem(item) {
        this.update_data.typetest_id = item.TypetestId;
        this.update_data.typetest_name = item.TypetestName;
        this.update_data.rules_id = item.RulesId;
        this.update_data.rules_name = item.RulesName;
    },
  
  goToLink(row){
      var result = ''
      result= `/rulespapikostickvalues/${row.item.Id}`
      return result
  },

  updateData() {
        const formData = new FormData();
        formData.append("TypetestId", this.update_data.typetest_id);
        formData.append("RulesId", this.update_data.rules_id);

        let contentType = `application/form-data`;

        Services.PostData(
          ApiService,
          `master/papi-kostick-map/`,
          formData,
          contentType,
          response => {
            if (response.status) {
              Swal.fire({
                title: "",
                text: "Data Update successfully.",
                icon: "success",
                heightAuto: true,
                timer: 1500
              });

              this.update_data.rules_id = '';
               this.update_data.rules_name = '';
               this.update_data.typetest_id = '';
               this.update_data.typetest_name = '';
              this.loadData();
            } else {
              Swal.fire({
                title: "",
                text: response.error || 'Update failed',
                icon: "info",
                heightAuto: true,
                timer: 1500
              });
            }
          },
        )
        .finally(() => {
          // Hide the dropdown regardless of success or failure
          this.$nextTick(() => {
            // if (this.$refs.myDropdown) {
            //   this.$refs.myDropdown.show = false;
            // }
          });
        });
      },

    async deleteItem(index) {
      let contentType = `application/form-data`;

      Services.DeleteData(
        ApiService,
        `master/rules-papi-kostick/${index}`,
        contentType,
        response => {
          if (response.status) {
            Swal.fire({
              title: "",
              text: "Data Delete successfully.",
              icon: "success",
              heightAuto: true,
              timer: 1500
            });
            this.loadData();
          } else {
            Swal.fire({
              title: "",
              text: response.error || 'Delete failed',
              icon: "info",
              heightAuto: true,
              timer: 1500
            });
          }
        },
      )
      .finally(() => {
        // Hide the dropdown regardless of success or failure
        this.$nextTick(() => {
          // if (this.$refs.myDropdown) {
          //   this.$refs.myDropdown.show = false;
          // }
        });
      });
      },

    loadData() {
      this.getMasterAgeScore1()
        .then(() => {
          // Lakukan sesuatu setelah data dimuat
          this.getMasterAgeScore()
        })
        .catch(error => {
          console.error(error);
        });
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Master", route: "/master" },
      { title: "Psychometric", route: "/metric" },
      { title: "Mapping PapiKostick", route: "/papikostickmap" }
    ]);
    this.loadData();
  }
};
</script>
<style>
.form-group {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.form-group label {
  margin-right: 10px;
  flex-grow: 1; /* Optional: Add this to make sure the label takes up the available space on the left */
}
.form-group input {
  flex-grow: 2; /* Optional: Add this to make sure the input field takes up more space on the right */
}
</style>
